.computer-main-container{
    display: flex;
    height: 100vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
   background: linear-gradient(to right, #859398, #5A6A71, #283048);
}
.computer-main-title-logo{
    /* width: 100px;
    position: absolute;
    top: 100px;
    left: 100px; */
    display: flex;
    width: 50%;
    padding-bottom: 25%;
    align-items: center;
    justify-content: center;
}
.computer-main-rightside-container{
    width: 50%;
    display: flex;
    font-size: 18px;
    flex-direction: column;
  
   
}
.aboutme-description-details{
    display: flex;
    position: relative;
    padding: 45px;
    text-align: start;
    font-weight: 400;
    color: #D3D3D3;
    font-family: 'Abramo', sans-serif;  
    font-size: 1.2em;
    animation: details 2s ease-in-out ;
     
}
.computer-main-title {
  background: linear-gradient(to right,#FF7F50,#00FFFF);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3rem; 
  font-weight: bold; 
  text-align: start;
  animation:title 0.5s ease-in-out forwards;
  padding-left: 50px;
  position: relative;
}

 .computer-image {
    width: 45%;
    padding: 0 100px 0 0;
    animation: computer 2s ease ;
    }






@keyframes details {
  0% {
    left: -100%; 
  }
  100% {
    left: 0%;
  }
}

@keyframes title {
  0% {
    transform: translateY(-100%); /* Text starts from above the element */
    opacity: 0; /* Initially hidden */
  }
  100% {
    transform: translateY(0); /* Text slides down to its original position */
    opacity: 1; /* Fully visible */
  }
}

@keyframes computer{
  from{opacity: 0;scale: 1;transform: translateX(500px);}
  to{ opacity: 1;scale: 1;transform: translateX(0px);}
}




   
@media only screen and (min-width: 768px) and (max-width: 959px){
.computer-image {
        padding: 0 100px 0 0
    }
     .computer-main-container {
        width: 100%;
        align-items: center;
        padding: 0px;
        flex-direction: column;

    }

    /* Hide logo in mobile view */
    .computer-main-title-logo {
        display: none;
    }

    /* Title at the top */
    .computer-main-title {
        font-size:3em;
        padding-left: 0;
        line-height: 2;
        order: 1;
        text-align: center;
        margin-bottom: 20px;
        padding-top: 20px;
    }

    /* Image in the middle */
    .computer-image {
        width: 80%;
        padding: 0;
        order: 2;
    }

    /* Right side content at the bottom */
    .computer-main-rightside-container {
        width: 100%;
        padding: 0;
        text-align: center;
    }

    .aboutme-description-details {
        padding: 0px;
        font-size: 1.5em;
         order: 3;
         width: 100%;
        text-align: center;
    }
}

@media (max-width: 767px) {
    .computer-main-container {
        width: 100%;
        align-items: center;
        padding: 0px;
        flex-direction: column;

    }

    /* Hide logo in mobile view */
    .computer-main-title-logo {
        display: none;
    }

    /* Title at the top */
    .computer-main-title {
        font-size: 1.8rem;
        padding-left: 0;
        line-height: 1.2;
        order: 1;
        text-align: center;
        margin-bottom: 20px;
        padding-top: 20px;
    }

    /* Image in the middle */
    .computer-image {
        width: 80%;
        max-width: 300px;
        padding: 0;
        margin: 20px auto;
        order: 2;
    }

    /* Right side content at the bottom */
    .computer-main-rightside-container {
        width: 100%;
        padding: 0;
        text-align: center;
    }

    .aboutme-description-details {
        padding: 0px;
        font-size: 1em;
         order: 3;
         width: 100%;
        text-align: center;
    }
}
.skills-page {

  color: #fff;
  text-align: center;
  padding: 50px 0;
  
   background: linear-gradient(to right, #859398, #5A6A71, #283048);
}
.compnent-title-style{
background-image: linear-gradient(#FF7F50,#00FFFF);
  color: transparent;
  background-clip: text;
  font-size: 3rem; 
  font-weight: bold; 
}

.heading{
    padding-bottom: 40px;
}

.heading p {
  font-size: 1.2em;
  margin-bottom: 50px;
  max-width: 800px;
  margin: 0 auto;
}

.icons-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
}

.icon {
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.5s ease-out;
  text-align: center;
}

.icon img {
  width: 80px;
  height: 80px;
}

.icon p {
  margin-top: 10px;
  font-size: 1.1em;
}

.icon-1, .icon-2, .icon-3, .icon-4, .icon-5, .icon-6, .icon-7, .icon-8, .icon-9, .icon-10, .icon-11, .icon-12, .icon-13, .icon-14 {
  transition-delay: 0s, 0.1s, 0.2s, 0.3s, 0.4s, 0.5s, 0.6s, 0.7s, 0.8s, 0.9s, 1s, 1.1s, 1.2s, 1.3s;
}

@media (max-width:767px) {
  .compnent-title-style{
    font-size: 1.8em;
    padding: 10px;
  }
  .icon img {
  width: 50px;
  height: 50px;
}
}

@media (min-width:767px) and (max-width:969px) {
  .compnent-title-style {
    font-size: 2em;
  }
}

.computer_graphic svg {
  width: 100%;
  height: 100%;
  max-width: 900px;
}

.computer_graphic svg .code_graph path {
  animation: float 4s ease-in-out infinite;
  transition: all .3s ease-out;
}

.computer_graphic svg .code_graph path:hover {
  fill: #F4A949 !important;
}

.computer_graphic svg .code_graph path:hover#path3776,
.computer_graphic svg .code_graph path:hover#path3780,
.computer_graphic svg .code_graph path:hover#path3772 {
  fill: inherit !important;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-300px);
  }
  100% {
    transform: translatey(0px);
  }
}

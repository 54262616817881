.App {
  text-align: center;
  background: linear-gradient(to right, #859398, #5A6A71, #283048);
}









.MainContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
}

.LeftHalf {
    width: 50%;
    height: 100vh;
   background: linear-gradient(to right,#B0E0E6,#FFA07A, #6A7B8D );
    display: flex;
}

.RightHalf {
    width: 50%;
    height: 100vh;
background: linear-gradient(to right, #6A7B8D, #FFA07A,#B0E0E6);



/* 
      background-image: linear-gradient(#FFDAB9,#FFB6C1,#00BFFF,#B0E0E6);
     background: -webkit-linear-gradient(#FFDAB9,#FFB6C1,#00BFFF,#B0E0E6);
     background: linear-gradient(to top, #FFDAB9,#FFB6C1,#00BFFF,#B0E0E6); */

    flex-direction: column,
}

.overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    height: 60vh;
    z-index: 10;
}

@media (max-width:969px) {
 .overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    height: auto;
    z-index: 10;
}
}
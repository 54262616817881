*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0 none;
  position: relative;
}

html {
  background: #000;
  box-sizing: border-box;
  font-family: 'Vollkorn', sans-serif;
  font-size: 1rem;
  color: #000;
}

@media screen and (min-width: 700px) {
  body > article {
    display: flex;
    flex-wrap: wrap;
  }
  article{
   background: linear-gradient(to right, #859398, #5A6A71, #283048);
  }

  figure {
    background: linear-gradient(to right, #859398, #5A6A71);
    width: calc(50% + 1px);
    height: 100vh;
    margin: 0 auto 10vh 0;
    position: sticky;
    top: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  figure:nth-of-type(2n)::after {
    right: 45%;
    left: 5vmin;
  }

  section {
       background: linear-gradient(to right,#5A6A71, #859398 );
    width: calc(50% + 1px);
    height: 100vh;
    margin: 0 0 10vh auto;
    position: sticky;
    top: 0;
    padding: 5vmin;
  }

  figure:nth-of-type(1), section:nth-of-type(1) {
    margin: 0 0 10vh 0;
    width: 50%;
  }

  figure:nth-of-type(2n) {
    margin: 0 0 10vh auto;
    /* box-shadow: -4px -4px 8px rgba(0, 0, 0, 0.4); */
  }

  section:nth-of-type(2n) {
    margin: 0 auto 10vh 0;
    /* box-shadow: 4px -4px 8px rgba(0, 0, 0, 0.4); */
  }

  figure:last-of-type, section:last-of-type {
    margin-bottom: 0;
  }


  section:nth-of-type(2n)::before {
    left: auto;
    right: 0;
    transform: translate(calc(50% - 1px), -50%) rotate(-45deg) scale(-1);
  }


  section:nth-of-type(2n)::after {
    right: 5vmin;
    left: 45%;
  }

  figure img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    object-position: center;
  }

  section > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 1rem;
  }
.project-container{
  background: linear-gradient(to right, #859398, #5A6A71, #283048);
      }

  a:hover, a:focus {
    -webkit-text-stroke: 1px #999;
  }
}

.iphone-frame {
  position: relative;
  display: inline-block;
}

.iphone-img {
  width: 300px;
  height: auto;
}

.iphone-screen {
  position: absolute;
  top: 8.5%;  /* Adjust based on your frame's screen area */
  left: 11%;  /* Adjust based on your frame's screen area */
  width: 78%; /* Adjust based on your frame's screen area */
  height: 80%; /* Adjust based on your frame's screen area */
  overflow: hidden;
}

.content-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projects-details-styles{
  font-size: 1.2em;
  font-weight: 400;
 font-family: 'Abramo', sans-serif;
 color: #eee;
 padding: 40px;
}
.project-logo-container{
  padding: 40px;
}
.project-techstack-style{
    color: transparent;
    background-clip: text;
    font-size: 2rem;
    margin-bottom: 50px;
    font-weight: bold;
    background-image: linear-gradient(#FF7F50 ,#00FFFF);

}
.hosted-site-link{
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: row;
}
.storelink-appstore-container{
   width: 50%;
display: flex;
justify-content: center;
align-items: center;
object-fit: cover;

}
.storelink-playstore-container{
width: 50%;
display: flex;
justify-content: center;
align-items: center;
object-fit: cover;

}
.image-techstack{
  width: 50px;
  height: 50px;
}
.img-appstore{
  width:60%;
  height: 20%;
}
.img-playstore{
  width:60%;
  height: 20%;
}

@media (max-width:767px){
  .project-container{
  background: linear-gradient(to right, #859398, #5A6A71, #283048);
      }
      .image-techstack{
  width: 40px;
  height: 40px;
}
.project-logo-container {
  padding: 0px;
}
}